/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Od listopada 2022 r. do czerwca 2023 trwały prace związane z inwestycją rozbudowy drogi krajowej nr 15 w miejscowości Kwieciszewo.  Realizacja miała na celu przede wszystkim poprawę bezpieczeństwa, zarówno jeżeli chodzi o kierowców, jak i pozostałych uczestników ruchu drogowego. Rozbudowa wpłynęła korzystnie także na samą estetykę drogi."), "\n", React.createElement(_components.p, null, "W ramach zostały wykonane następujące zdania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbudowa drogi krajowej nr 15,"), "\n", React.createElement(_components.li, null, "rozbudowa skrzyżowania DK 15 z DP2435C,"), "\n", React.createElement(_components.li, null, "budowa zatoki postojowej,"), "\n", React.createElement(_components.li, null, "przebudowa i budowa chodników dla pieszych, zjazdów, wysp dzielących"), "\n", React.createElement(_components.li, null, "przebudowa systemu odwodnienia"), "\n", React.createElement(_components.li, null, "budowa oraz przebudowa oświetlenia ulicznego,"), "\n", React.createElement(_components.li, null, "przebudowa i rozbiórka kolizyjnych odcinków sieci uzbrojenia terenu (sieć elektroenergetyczna, sieć telekomunikacyjna),"), "\n", React.createElement(_components.li, null, "budowa kanału technologicznego,"), "\n", React.createElement(_components.li, null, "wycinka kolidujących drzew i krzewów,"), "\n", React.createElement(_components.li, null, "wykonanie oznakowania pionowego i poziomego oraz elementów bezpieczeństwa ruchu drogowego."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
